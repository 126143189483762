import { mergeCn } from "../utils";

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly className?: string;
}

export const DownloadButton = ({
  className,
  children,
  ...restProps
}: IButtonProps) => (
  <button
    className={mergeCn(
      "px-8 py-2 font-bold bg-[#bcdac1] hover:bg-[#aacabb] text-mainGreen",
      className
    )}
    {...restProps}
  >
    {children}
  </button>
);
