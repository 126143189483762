import { mergeCn } from "../utils";

export const OuterSection = ({
  className,
  children,
}: {
  className?: string;
  children?: string | React.ReactNode;
}) => (
  <div className={mergeCn("w-full flex flex-col items-center", className)}>
    {children}
  </div>
);

export const InnerSection = ({
  className,
  children,
}: {
  className?: string;
  children?: string | React.ReactNode;
}) => (
  <div
    className={mergeCn("max-w-[60rem] w-fit py-14 px-6 sm:px-10", className)}
  >
    {children}
  </div>
);
