import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./views/Home";
import { ContactPage } from "./views/ContactPage";
import Logo from "./assets/BCRS_Logo.png";
import { ReactComponent as MobileNavButton } from "./assets/bars-solid.svg";
import { FooterSection } from "./components/FooterSection";
import { useState } from "react";
import { mergeCn } from "./utils";

const links = [
  {
    label: "About",
    href: "/",
  },
  {
    label: "Contact Us",
    href: "/contact",
  },
];

function App() {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  return (
    <main className="w-full h-[100dvh] overflow-y-auto relative text-mainGreen flex flex-col underline-offset-4">
      <div
        id="navbar"
        className="py-4 shadow sticky top-0 w-full flex justify-center bg-white"
      >
        <div className="px-4 sm:px-8 w-full max-w-[60rem] flex justify-between items-end">
          <div className="h-fit">
            <a href="/">
              <img src={Logo} className="w-[8rem] sm:w-[10rem] md:w-[12rem]" />
            </a>
          </div>
          <div className="hidden sm:flex flex-col sm:flex-row gap-y-3 sm:gap-x-8 md:text-xl whitespace-nowrap py-1">
            {links.map((link) => (
              <a href={link.href}>
                <div className="text-right hover:underline">{link.label}</div>
              </a>
            ))}
          </div>
          <div className="sm:hidden relative">
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowMobileMenu(true);
              }}
            >
              <MobileNavButton
                width={40}
                height={40}
                className="fill-mainGreen"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        className={mergeCn(
          "z-40 block fixed inset-0 w-full h-[100dvh] p-5 bg-black/50",
          { hidden: !showMobileMenu }
        )}
        onClick={() => setShowMobileMenu(false)}
      >
        <div className="flex flex-col justify-center gap-4 px-6 py-6 w-full h-fit text-xl bg-white rounded-2xl shadow-lg">
          {links.map((link) => (
            <a href={link.href} onClick={() => setShowMobileMenu(false)}>
              <div className="hover:underline border rounded-lg px-4 py-4 shadow">
                {link.label}
              </div>
            </a>
          ))}
          <button
            className="text-lg mt-4 py-1 px-4 hover:underline"
            onClick={() => setShowMobileMenu(false)}
          >
            Close
          </button>
        </div>
      </div>
      <div className="flex-grow">
        <BrowserRouter>
          <Routes>
            <Route index Component={Home} />
            <Route path="/contact" Component={ContactPage} />
          </Routes>
        </BrowserRouter>
      </div>
      <FooterSection />
    </main>
  );
}

export default App;
