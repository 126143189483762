import { InnerSection, OuterSection } from "../components/SectionContainer";
import { mergeCn } from "../utils";
import { ReactComponent as Icon1 } from "../assets/icon_1.svg";
import { ReactComponent as Icon2 } from "../assets/icon_2.svg";
import { ReactComponent as Icon3 } from "../assets/icon_3.svg";
import { ReactComponent as Icon4 } from "../assets/icon_4.svg";
import { DownloadButton } from "../components/DownloadButton";
import { ReactComponent as NewTabIcon } from "../assets/new_tab.svg";

const benefitItems = [
  {
    Icon: Icon1,
    label: "Reduce landfill",
  },
  {
    Icon: Icon2,
    label: "Cleaner environment",
  },
  {
    Icon: Icon3,
    label: "Reduce use of virgin materials",
  },
  {
    Icon: Icon4,
    label: "Reduce carbon footprint",
  },
];

export const Home = () => (
  <>
    <OuterSection className="bg-hero text-white bg-cover bg-center">
      <div className="w-full bg-mainBlue bg-opacity-80 flex flex-col items-center">
        <InnerSection className="flex flex-col gap-4">
          <h1 className="font-black text-3xl sm:w-[22rem] md:w-[24rem]">
            Beverage Container Return Scheme (BCRS)
          </h1>
          <div
            className={mergeCn(
              "leading-tight flex flex-col gap-3",
              "md:text-lg md:leading-[1.35rem] sm:w-3/4 md:w-2/3"
            )}
          >
            <p>
              The commencement date for the BCRS is scheduled for 1 April 2026.
            </p>
            <p>This is the first day BCRS products can be sold to consumers.</p>
            <p>
              There is a grace (transition) period from 1 April to 30 June 2026
              where both “old” products without deposit mark and new BCRS
              products with deposit mark can be sold from retailers (outlets) to
              consumers.
            </p>
            <p>
              From 1 July 2026, after full implementation, only BCRS products
              with deposit mark can be sold on the Singapore market.
            </p>
          </div>
        </InnerSection>
      </div>
    </OuterSection>
    <OuterSection className="text-black text-center">
      <InnerSection className="flex flex-col gap-6">
        <h2 className="font-black text-3xl">Benefits of the BCRS.</h2>
        <div
          className={mergeCn(
            "leading-tight flex flex-col gap-3",
            "md:text-lg md:leading-[1.35rem]"
          )}
        >
          <p>
            With waste and pollution becoming a bigger problem, the BCRS will
            provide individuals and community groups with an incentive to return
            beverage containers for recycling.
          </p>
          <p>
            This helps keep waste out of our landfill and also reduces use of
            virgin materials which will help to reduce our carbon footprint.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4 uppercase font-black text-lg leading-tight">
          {benefitItems.map((i) => {
            const Icon = i.Icon;
            return (
              <div className="flex flex-col items-center gap-2 sm:gap-4">
                <Icon className="w-28 sm:w-36 h-auto" />
                <div className="w-full">{i.label}</div>
              </div>
            );
          })}
        </div>
      </InnerSection>
    </OuterSection>
    <OuterSection className="bg-black/50 bg-[url('./assets/banner_2.jpg')] bg-blend-darken text-white bg-cover">
      <InnerSection className="flex flex-col gap-8 md:text-lg">
        <div
          className={mergeCn(
            "leading-tight flex flex-col gap-3",
            "md:leading-[1.35rem] sm:w-3/4 md:w-2/3"
          )}
        >
          <p>
            As a producer or importer of beverages, you have a key role to play
            in the BCRS Scheme.
          </p>
          <p>
            Products must be printed with a deposit mark and a readable barcode.
            For more details, please download the information below.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <div>Download 'Policy Artwork Design Guidelines' here:</div>
          <div>
            <a
              href="files/BCRS_Policy-Artwork-Design-Guidelines_Updated-November-2024.pdf"
              target="_blank"
            >
              <DownloadButton className="flex items-center gap-2">
                Download PDF
                <NewTabIcon
                  className="fill-mainGreen stroke-mainGreen"
                  width={22}
                  height={22}
                />
              </DownloadButton>
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div>Download the 'Deposit Mark Design' files here:</div>
          <div className="flex gap-8">
            <a
              href="files/BCRS_Deposit_Mark_Positive-Negative.zip"
              target="_blank"
            >
              <DownloadButton className="flex items-center gap-2">
                Download Image Zip
                <NewTabIcon
                  className="fill-mainGreen stroke-mainGreen"
                  width={22}
                  height={22}
                />
              </DownloadButton>
            </a>
          </div>
        </div>
      </InnerSection>
    </OuterSection>
  </>
);
