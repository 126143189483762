import { InnerSection, OuterSection } from "../components/SectionContainer";

export const ContactPage = () => (
  <OuterSection>
    <InnerSection className="w-full flex flex-col gap-8 text-black">
      <h1 className="font-black text-2xl sm:w-[22rem] md:w-[24rem]">
        Contact us
      </h1>
      <div>Email us at the following address for more queries</div>
      <div className="flex font-bold">
        <div className="w-20">Email :</div>
        {/* <a href="mailto:info@bcrs.sg" className="hover:underline"> */}
        info@bcrs.sg
        {/* </a> */}
      </div>
    </InnerSection>
  </OuterSection>
);
